import React, { useEffect, lazy, Suspense } from 'react';
import { useClearCache } from 'react-clear-cache';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider, theme } from '@material-ui/core/styles';
import { grey, red, blue, blueGrey } from '@material-ui/core/colors';
import ScrollToTop from './ScrollToTop';
import './App.css';
import { useGlobalStateTheme } from './hookstate_theme';
import { useGlobalStateProfile } from './hookstate_profile';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageTitleUpdater from './PageTitleUpdater';
import { Typography } from '@material-ui/core';
const CertificateProgramsPages = lazy(() => import('./CertificateProgramsPages'));
const ClassVideo = lazy(() => import('./ClassVideo'));
// import ClassVideo from './ClassVideo';
const Video = lazy(() => import('./Video'));
const Dashboard = lazy(() => import('./Dashboard'));
// import Dashboard from './Dashboard';
const PreviewDashboard = lazy(() => import('./PreviewDashboard'));
const LandingPage = lazy(() => import('./LandingPage'));
const Page = lazy(() => import('./Page'));
const Main = lazy(() => import('./Main'));
const SignInScreen = lazy(() => import('./SignInScreen'));
const ProfileSettings = lazy(() => import('./ProfileSettings'));
const ProfilePage = lazy(() => import('./ProfilePage'));
const EnrollmentPage = lazy(() => import('./EnrollmentPage'));
const AssignmentPage = lazy(() => import('./AssignmentPage'));
const UpgradePage = lazy(() => import('./UpgradePage'));
const MainThread = lazy(() => import('./MainThread'));
const InstructorGuidelines = lazy(() => import('./InstructorGuidelines'));
const FourOhFour = lazy(() => import('./text/FourOhFour'));
const UpcomingWorkshops = lazy(() => import('./text/UpcomingWorkshops'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Registry = lazy(() => import('./Registry'));
const Experiment = lazy(() => import('./Experiment'));
const RedirectCourses = () => <Redirect to="/courses" />

const App = () => {

  const globalStateTheme = useGlobalStateTheme();
  const globalStateProfile = useGlobalStateProfile();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(
    () => {
      (async () => {
        if(globalStateProfile.get().profileLoaded) {
          await fetch(
              `${process.env.REACT_APP_SERVER}/profile-settings`,
              {
                  method: 'PUT',
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    displayName: globalStateProfile.get().displayName,
                    bio: globalStateProfile.get().bio,
                    themePreference: globalStateTheme.get().theme
                  })
              }
          )
          .catch((error) => console.log(error));

          // const json = backend.json();
          globalStateProfile.set(
            {
              ...globalStateProfile.get(),
              profileUpdateMessage: 'Preferences updated successfully.',
              profileUpdateVariant: {variant: 'success'},
              updateTime: Date.now()
            }
          )
          localStorage.setItem('themePreference', globalStateTheme.get().theme)
        }
      })()
    }, [
      globalStateTheme.get().theme
    ]
  )

  useEffect(
    () => {
        (async () => {

          if(!localStorage.getItem('email')) {
              localStorage.clear();
              globalStateProfile.set({})
          }


          !isLatestVersion && emptyCacheStorage();

          if(!globalStateProfile.get().profileLoaded && globalStateProfile.get().loggedIn) {
            const backend = await fetch(
                `${process.env.REACT_APP_SERVER}/profile-settings`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                    }
                }
            ).catch(error => {
              globalStateProfile.set(
                {
                    ...globalStateProfile.get(),
                    profileUpdateMessage: 'Something went wrong. Please try again later.',
                    profileUpdateVariant: {variant: 'error'},
                    updateTime: Date.now()
                }
              )
            });

            const json = (await backend.json()).message;

            let details = {
              firstName: json.firstName,
              lastName: json.lastName,
              email: json.email,
              displayName: json.displayName,
              bio: json.bio,
              avatar: json.avatar,
              _id: json._id,
              notificationsLastDate: json.notificationsLastDate
            };

            if(json.googleAccount) {

              if(!json.firstName) {
                details.firstName = json.googleAccount.givenName
              }
              if(!json.lastName) {
                details.lastName = json.googleAccount.familyName
              }
              if(!json.displayName) {
                details.displayName = ''
              }
              if(!json.bio) {
                details.bio = ''
              }
              if(!json.avatar) {
                details.avatar = json.googleAccount.imageUrl
              }

              globalStateProfile.set(
                {
                    ...globalStateProfile.get(),
                    profileLoaded: true,
                    profileUpdated: true,
                    loggedIn: true,
                    _id: details._id,
                    firstName: details.firstName,
                    lastName: details.lastName,
                    googleAccount: details.googleAccount,
                    displayName: json.displayName,
                    bio: json.bio,
                    avatar: details.avatar,
                    email: json.email,
                    notificationsLastDate: json.notificationsLastDate,
                    themePreference: json.themePreference
                }
              )
            }
            else {
              globalStateProfile.set(
                  {
                      ...globalStateProfile.get(),
                      theme: json.themePreference ? json.themePreference : 'light',
                      profileLoaded: true,
                      profileUpdated: true,
                      loggedIn: true,
                      _id: json._id,
                      firstName: json.firstName,
                      lastName: json.lastName,
                      displayName: json.displayName,
                      avatar: json.avatar,
                      bio: json.bio,
                      email: json.email,
                      notificationsLastDate: json.notificationsLastDate,
                      themePreference: json.themePreference
                  }
              )
            }
          }
        })()
    },[]
  )

  const outerTheme = createTheme({
    typography: {
      // htmlFontSize: 20,
      fontFamily: [
        'Poppins, Arial, sans-serif'
      ].join(',')
    },
    palette: {
      type: globalStateTheme.get().theme,
      primary: {
        main: globalStateTheme.get().theme === 'dark' ? "rgb(0, 39, 255)": "rgb(0, 39, 255)"//"rgb(0, 39, 255)" : "rgb(0, 39, 255)",
      },
      secondary: {
        main: blueGrey[100]
      },
      dark: {
        main: grey['A700'],
      },
      background: {
        default: "#191c21",//'rgb(41, 44, 52)',//'#222',
        paper: 'rgba(255,255,255,0.05)',

        default2: '#0c0d0f',//'#191c21',///'rgb(41, 44, 52)',
        paper2: 'rgb(43, 42, 51)'//'rgb(66, 65, 77)'
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: 'rgba(0,0,0,0.35)',
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            color: '#fff',
            backgroundColor: 'rgba(0,0,0,0.25)',
          }
        }
      },
      MuiTypography: {
        colorTextPrimaryLight: {
          color: 'red'
        }
      }
    },
    dimensions: {
      drawerWidth: 350,
      drawerWidthSm: 275
    }
  });

  /*
  return(
          <Box
          pl={2}
          pr={2}
          pt={2}
          style={{
            display: 'block',
            position: 'relative',
            height: '100vh',
            // width: '100vw'
           }}>
              <Grid container>
                <Grid item sm={3} >
                  <Skeleton variant="rect" width={100} height={24} style={{marginBottom: 36}}/>      
                  <Skeleton variant="rect" width={133} height={240} />
                </Grid>
                <Grid item sm={9}>
                  <Box display='flex' justifyContent="end">
                    <Skeleton variant="rect" width={100} height={24} />
                  </Box>
                </Grid>
              </Grid>                
        </Box>
  )*/  


  // const history = useHistory();
  // console.log('history', useHistory())
  // useEffect(() => {
  //     history.listen((location) => {
  //         console.log(`You changed the page to: ${location.pathname}`)
  //     })
  // }, [history])

  return (
    <ThemeProvider theme={outerTheme}>
      <BrowserRouter>
        <PageTitleUpdater />
        <Suspense fallback={
          <Box style={{
              display: 'block',
              position: 'relative',
              height: '100vh',
              width: '100vw'
          }}>
            <center 
            style={{
                width: '100px', 
                height: '100px', 
                display: 'block', 
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }
          }>
                {/* <CircularProgress size={64} /> */}
                <Typography color='textPrimary'>
                  <strong>BigNumber</strong>
                </Typography>
            </center>
          </Box>
          }>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Main}/>
            <Route path="/about" exact component={Main}/>
            <Route path="/courses" exact component={Main}/>
            <Route path="/pricing" exact component={Main}/>
            <Route path="/faqs" exact component={Main}/>
            <Route path="/account/signin" exact component={SignInScreen}/>
            <Route path="/account/signup" exact component={SignInScreen}/>
            <Route path="/faculty/instructor-guidelines" exact component={InstructorGuidelines}/>
            <Dashboard path="/dashboard" exact component={ProfileSettings}/>
            <Dashboard path="/dashboard/profile-settings" exact component={ProfileSettings}/>
            <Dashboard path="/dashboard/profile-page" exact component={ProfilePage}/>
            <Dashboard path="/dashboard/enrollments" exact component={EnrollmentPage}/>
            <Dashboard path="/dashboard/enrollments/upgrade/:id" exact component={UpgradePage}/>
            <Dashboard path="/dashboard/enrollments/:id" classMode={true} exact component={ClassVideo}/>
            <Dashboard path="/dashboard/enrollments/:id/:mod/:eva_title" classMode={true} exact component={ClassVideo}/>
            <Dashboard path="/dashboard/assignment" exact component={AssignmentPage}/>
            <PreviewDashboard path="/preview/enrollments/:id" classMode={true} exact component={ClassVideo}/>
            <PreviewDashboard path="/preview/enrollments/:id/:mod/:eva_title" classMode={true} exact component={ClassVideo}/>
            <Dashboard path="/dashboard/main-thread" exact component={MainThread}/>
            <Dashboard path="/dashboard/main-thread/:post" exact component={MainThread}/>
            <Dashboard path="/dashboard/workshops" exact component={UpcomingWorkshops}/>
            <Dashboard path="/dashboard/videos/:category" exact component={Video}/>
            <Route path="/courses/:id" exact component={CertificateProgramsPages}/>
            <Route path="/certificate-programs/:id" exact component={RedirectCourses}/>
            {/*<PrivateRoute path="/courses/:id" exact component={Course}/>*/}
            <PrivateRoute path="/shop" exact component={Page}/>
            <Route path="/workshops/:id" exact component={Page}/>
            <Route path="/registry" exact component={Registry}/>
            <Route path="/registry/:id" exact component={Registry}/>
            <Route path="/:id" exact component={Page}/>
            <Route path='/404' component={FourOhFour} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;